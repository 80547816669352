import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/188f535406586f2cf8ca273662f2151a/e5166/outriders.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.50965250965252%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAABjpzmWqxIj//EABoQAAICAwAAAAAAAAAAAAAAAAECABEDEzH/2gAIAQEAAQUCQhZtMarVyoOV43f/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABsQAAIBBQAAAAAAAAAAAAAAAAAQIgEhIzFh/9oACAEBAAY/Ao1kZOqxtf/EABsQAQEBAAMBAQAAAAAAAAAAAAERACFBUTGB/9oACAEBAAE/IQD4Ey5IScJJzugkxW5+ZgXv4ZVr7v/aAAwDAQACAAMAAAAQ0B//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAVH/2gAIAQMBAT8Q2p//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBFB/9oACAECAQE/EGnI/wD/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMUFhcf/aAAgBAQABPxBgMSCCO93LhkEWZB+6wtqlsjzIWs4gvX2YBUFL9lvmJOVS6mf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Outriders",
            "title": "Outriders",
            "src": "/static/188f535406586f2cf8ca273662f2151a/af659/outriders.jpg",
            "srcSet": ["/static/188f535406586f2cf8ca273662f2151a/8356d/outriders.jpg 259w", "/static/188f535406586f2cf8ca273662f2151a/bc760/outriders.jpg 518w", "/static/188f535406586f2cf8ca273662f2151a/af659/outriders.jpg 1035w", "/static/188f535406586f2cf8ca273662f2151a/e5166/outriders.jpg 1200w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let me get this out of the way: Outriders is not a great game. There are `}<em parentName="p">{`LOTS`}</em>{` of flaws, but the good news is none of these flaws are gamebreaking, and most should be easily fixable within a few weeks. None of these issues prevented me from thoroughly enjoying my time with the game, either.`}</p>
    <h2>{`The Bad stuff`}</h2>
    <p>{`Let's go over this stuff first so we can finish on a high note.`}</p>
    <h3>{`Audio`}</h3>
    <p>{`This was one of the most blatant issues (issues issues). The audio in this game needs serious work (work work). Almost every NPC you'd walk by in the starter town would have their audio echo multiple times, as though the line was spoken as you entered some trigger and then again as you left it. Not only that but since there were so many people crammed together, you'd hear this cacophony of voices that you couldn't quite make out most of the time, and you'd only really understand the person the captions decided to highlight. Not only does the audio echo but it has another big issue: volume. Now this usually happened when turning in quests, but occassionally the person I was about to talk to would have their volume turned up to 11 while they rifled off whatever inane line of text they were speaking as I passed through their conversation trigger only to have it immediately drop down to normal levels after the cutscene had finish loading.`}</p>
    <p>{`Beyond the echo and volume issues, audio syncing during the demo's plentiful (put mildly) cutscenes is abysmal. Often times with the game not even trying to move the speakers lips, and others they'd be just behind or just opening and closing in some vain effort to look like the developers knew this should happen, but couldn't be bothered at this time.`}</p>
    <p>{`Finally the music. I'm not sure there is any. That's an exaggeration of course, something had to be playing while shooting bad guys right? But there's nothing memorable at all. Nothing that felt suitably epic during any of the cutscenes or during the first story boss fight. I hope later set pieces in the game fix this problem.`}</p>
    <h3>{`Graphics`}</h3>
    <p>{`What I'm about to say here doesn't apply to the first 90% of the prologue, before you exit cryo. That part of the game looked absolutely amazing, and I played on the PS4. If they can fix the rest of the game to look like the early bit of the prologue, this could be one of the best looking games of the year.`}</p>
    <p>{`Now that that disclaimer is out of the way, let me also state that I don't need good graphics for a game to be good (see above when I said I really enjoyed the demo), but I know that on the PS4 and the PS5 (since I played with a friend) they are pretty bad. My devastators arms looked like smeared muddy cylinders, and most of the environments did as well. This is further complicated by the lighting in several of the arenas, that are at least so far relegated to the side quests in town. In these arenas there is so little light that its hard to see anything of an enemy beyond the red triangle threat indicator. This was somewhat alleviated by turning the gamma up to maximum. I get it, this is a grim dark game in a grim dark future, but you don't need to be pitch black to get that across. A great example of this is Control. Many of the areas in that game are pretty dark, and the building as a whole is supposed to feel that way, but it never comes off a pitch black because of the excellent lighting...and maybe the color palette.`}</p>
    <p>{`One last minor gripe, and I know the demo encompasses the first chapter of what is a 35-40 hour game, but man the gear looks very bland. I picked up a blue that I swear was just Jake from State Farm's khakis.`}</p>
    <h3>{`Gameplay`}</h3>
    <p>{`Don't worry there won't be much here. There were a few gameplay issues. As mentioned I played primarily the devastator who excels at close range combat, so I used a bunch of shotguns. The main issue with the shotguns is that the hit registration seems to be very bad. You'd shoot and then a second or two later the enemy would explode or their health bars would deplete. Luckily this never seemed to cost me more than a few wasted rounds, but it's something that is very noticeable and needs to be fixed. I had two disconnect issues as well, granted it's the demo so this isn't entirely unheard of, but I know from other parts of the interwebs that I was not the only one, this is definitely an issue that I expect to be quashed early, if not by full launch of the game. Lastly my friend had an issue where he was unable to turn in a few quests we had done together that were definitely being tracked and updated in his log, but we ended up doing twice because he couldn't get the rewards. We suspect this might have been because he did a drop and rejoin, but we're unsure. An issue I found particularly annoying was that you couldn't mark something for dismantling until the tool tip has finished showing up, which takes about a second.`}</p>
    <h2>{`The Good`}</h2>
    <h3>{`Story`}</h3>
    <p>{`I may be in the minority here, but I really enjoyed the main story beats and world building they pulled off in the demo. I want to know about the floating black goo and what's sending that signal and just how effed up Enoch really is or if the people who sent us here knew about any of this. Also what are those giant striders that look like they should be Sinspawn? Now so far this doesn't apply to the handful of side quests we've been given. The little bits of story there just seem to exist to set up a reason for you or someone else to shoot some random NPC in the head, but I hope they come up with better story for the non-main quest line missions.`}</p>
    <h3>{`Gameplay`}</h3>
    <p>{`OK. The meat and potatoes of this game. The gunplay here is pretty solid. It's not at the same level as a game like Destiny (few things are), but it works, minus that one issue I saw with the shotgun hit registration. There seem to be a lot of different types of guns too, which creates a nice variety of play styles. The automatic shotgun was a personal favorite of mine. Before we get to the classes there's one other thing we should talk about: the cover. This game `}<em parentName="p">{`looks`}</em>{` like a cover shooter. It's not. Taking cover is a last resort for you as you heal primarily when dealing damage and there's only a base level of health you can regen to if you get super low. The cover exists primarily to give your enemies something to hide behind as you embark on your murderous rampage. What allows you to go on this rampage? While the weapons help the big damage, and fun, comes from using your class abilities. There are four classes and they play quite differently.`}</p>
    <h4>{`Devastator`}</h4>
    <p>{`This is where I spent the bulk of my time. You get four abilities in the demo, a conical ground pound that interrupts and goes through barriers, stoneskin that reduces damage by a large percentage, a leap into the air that does AOE damage to anyone you land near and probably turning them into paste, and the ability to catch and throw back enemy bullets. There wasn't a dull moment with this guy. He heals by killing enemies close to him, so you're gonna be using shotguns a lot, for that big burst damage. This will definitely be the class I start with when the game launches.`}</p>
    <h4>{`Technomancer`}</h4>
    <p>{`Outriders take on the pet/summoning class. You get two different turrets and a grenade and the blighted rounds abilities. It also heals just by doing damage and this applies to your turrets. This guy was pretty fun to play, if there was anything that seemed a bit weird its that I couldn't quite nail down how long you had to hold the ability button to get the aiming arc for the grenade and ice turret, something I'm sure I'd get used to. Of the three classes I played, this guy had the best melee attacks which is odd considering you're incentivized to play from a distance with this class.`}</p>
    <h4>{`Trickster`}</h4>
    <p>{`I hated every second I played this class. It arguably gets better when you get the ability to teleport behind a person, but at least solo, nothing felt really strong. In a group setting though, the time stop move will be bonkers, being able to synch up big abilities when a group of enemies are stuck inside will be a recipe for great fun.`}</p>
    <h4>{`Pyromancer`}</h4>
    <p>{`I didn't play this class, but my friend did and I never got tired of watching a man on fire rise up and explode taking his friends with him. He heals by damaging things that are on fire which seems easy enough to do, and will be easy enough to pull during boss fights making solo play viable.`}</p>
    <h3>{`The Loot`}</h3>
    <p>{`This is a looter shooter, in the vein of Destiny, so it needs good loot to survive and here they really knocked it out of the park. Even at the low levels you get to in the demo, there are still plenty of interesting choices to make. I've seen gear add not just crit chance or damage to your guns, but also add an AOE DoT effect to the bullets, etc. Most importantly however, there are perks on the armor that drastically change the abilities of your class. I got one piece of gear that added a pulsing AOE bleed effect to Golem (my stoneskin attack). Previously I had been using the bullet catch skill, but this gave me another reason to switch that back out and it was amazing. This hints at big build diversity that will be available over the course of the whole game instead of just at the end of it, like in Destiny.`}</p>
    <h3>{`Systems`}</h3>
    <p>{`There are two great features here I'd like to talk about. The first I saw in Remnant: From the Ashes, and that's the ability to set your story point and allow you to replay the game from certain points. This is a great feature for a looter shooter, especially one that isn't just going to live forever in the game-as-a-service model. The other was the drop out/drop in feature. This is awesome when playing with friends. All they have to do is click a button in the lobby and boom, they're right there with you and this is game where playing with someone only adds to the enjoyment.`}</p>
    <h2>{`Overall Impressions`}</h2>
    <p>{`I was super happy with the game, coming into the demo with absolutely zero expectations. It may not be the prettiest game or the best sounding, but the actual gameplay itself is a ton fun. I'll definitely be picking this up when it launches.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      